<template>
  <div class="card-body">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      loading-text="Malumot yuklanmoqda..."
      :items="allContracts"
      no-data-text="Malumot kiritilmagan"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:[`item.action`]="{ item }">
        <Action :index="item"></Action>
      </template>
      <!-- date -->
      <template v-slot:[`item.reg_date`]="{ item }">
        {{ item.reg_date | formatDate }}
      </template>
      <!-- end date -->
      <template v-slot:[`item.end_date`]="{ item }">
        {{ item.end_date | formatDate }}
      </template>
    </v-data-table>
    <div class="text-left mt-5">
      <v-pagination
        v-model="currentPage"
        :total-visible="7"
        @input="getPostData(currentPage)"
        :length="Math.ceil(getCount.count / 10)"
      ></v-pagination>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import Action from './Action'
export default {
  components: { Action },
  data: () => ({
    perPage: 10,
    currentPage: 1
  }),
  beforeCreate() {
    this.$store.dispatch('getKontragentsContractsList')
  },
  created() {
    this.currentPage = parseInt(this.$route.params.id, 10)
    this.getPostData(this.currentPage)
  },
  watch: {
    '$route.params.id': function (id) {
      this.currentPage = parseInt(id, 10)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.NUMBER'),
          value: 'reg_number'
        },
        {
          text: this.$t('TABLE_HEADER.INN'),
          value: 'counteragent_inn'
        },
        {
          text: this.$t('TABLE_HEADER.DATE'),
          value: 'reg_date'
        },
        {
          text: 'Tugash sanasi',
          value: 'end_date'
        },
        {
          text: this.$t('TABLE_HEADER.CONTRAGENT'),
          value: 'countragent_name'
        },
        {
          value: 'action',
          text: '',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getCount() {
      return this.$store.state.requests.kontragentsContractsList
    },
    allContracts() {
      var data = []
      data = this.$store.state.requests.kontragentsContractsList.results
      if (data !== undefined && data.length !== undefined) {
        return data
      } else return data
    }
  },
  methods: {
    getPostData(value) {
      if (this.$store.state.requests.filterData.data !== undefined) {
        const asd = {
          id: value,
          payload: this.$store.state.requests.filterData.data
        }
        this.$store.dispatch('contractsSearchPerPages', asd)
        if (this.$route.path !== '/allcontracts/' + value) {
          this.$router.push('/allcontracts/' + value)
        }
      } else {
        this.$store.dispatch('getKontragentsContractsList', value)
        if (this.$route.path !== '/kontragents/contract/' + value) {
          this.$router.push('/kontragents/contract/' + value)
        }
      }
    }
  }
}
</script>
<style scoped>
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: unset !important;
  overflow-y: unset !important;
}
.v-data-table__wrapper {
  overflow-x: inherit !important;
  overflow-y: inherit !important;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
</style>
