<template>
  <div>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <i class="ki ki-bold-more-ver"></i>
      </template>
      <!--begin::Navigation-->
      <div class="navi navi-hover" style="width: 250px">
        <b-dropdown-text tag="div" class="navi-item">
          <router-link
            :to="'/kontragents/contract/details/' + index.id"
            class="navi-link"
          >
            <span class="navi-icon">
              <i class="flaticon-file-1 text-success"></i>
            </span>
            <span class="navi-text">{{ $t('ACTION.DETAILS') }} </span>
          </router-link>
          <router-link
            :to="'/kontragents/contract/update/' + index.id"
            class="navi-link"
          >
            <span class="navi-icon">
              <i class="flaticon-edit-1 text-warning"></i>
            </span>
            <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
          </router-link>
          <!-- <router-link :to="'/adddopkontragentcontract/' + index.id" class="navi-link">
            <span class="navi-icon">
              <i class="flaticon2-file-1"></i>
            </span>
            <span class="navi-text">Qoshimcha shartnoma+</span>
          </router-link> -->
          <!-- <router-link :to="'/contractappenreg/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-file-1"></i>
          </span>
          <span class="navi-text">Maxsus ilova +</span>
        </router-link> -->
        </b-dropdown-text>
      </div>
    </b-dropdown>
  </div>
</template>

<script></script>
<style lang="scss">
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
export default {
  name: 'ContractsAction',
  data: () => ({
    dialog: false,
    menu1: false,
    menu1: false
  }),
  props: {
    index: Object
  },
  beforeCreate() {
    this.$store.dispatch('getKontragentsContractsListWithoutPg')
  },
  computed: {
    contagetnList() {
      const data = this.$store.state.requests.kontragetnsWithoutPg
      return data
    }
  },
  methods: {
    save() {
      const data = {
        countragent: this.index.countragent,
        end_date: this.index.end_date,
        reg_number: this.index.reg_number,
        reg_date: this.index.reg_date
      }
      console.log(data)
      this.$store.dispatch('countragetContractUpdate', {
        data: data,
        pageIndex: this.$route.params.id,
        id: this.$route.params.id
      })
      this.dialog = true
    }
  },
  components: {},
  mounted() {}
}
</script>
